import { HorizontalBar, PageName } from "./About/About.styles";
import { isMobile } from "react-device-detect";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import ReactCardFlip from "react-card-flip";
import styled from "styled-components";
import { motion } from "framer-motion";

// images
import Tightrope from "../../assets/projectImages/tightrope.png";
import RicochetRobots from "../../assets/projectImages/ricochet.png";
import SarcasmDetection from "../../assets/projectImages/neuralnet.png";
import GalaxyRaider from "../../assets/projectImages/GalaxyRaider.png";
import Logo from "../../assets/logo.png";
import MedMapsImage from "../../assets/projectImages/MedMaps.png";
import CovidCalc from "../../assets/projectImages/covidcalc.png";
import MysteryBugLab from "../../assets/projectImages/mysterybuglab.png";

//icons
import AmazonS3Icon from "../../assets/projectImages/amazons3.png";
import NodeJSIcon from "../../assets/projectImages/nodejs.png";
import PythonIcon from "../../assets/projectImages/python.png";
import FlaskIcon from "../../assets/projectImages/flask.png";
import TensorflowIcon from "../../assets/projectImages/tensorflow.png";
import JavascriptIcon from "../../assets/projectImages/javascript.png";
import CSSIcon from "../../assets/projectImages/css.png";
import DiscordIcon from "../../assets/projectImages/discord.png";
import HTMLIcon from "../../assets/projectImages/html.png";
import ReactIcon from "../../assets/projectImages/react.png";
import SwiftIcon from "../../assets/projectImages/swift.png";
import MongoDBIcon from "../../assets/projectImages/mongoDB.png";
import MySQLIcon from "../../assets/projectImages/MySQL.png";
import GmailIcon from "../../assets/projectImages/gmail.png";
import GoogleDriveIcon from "../../assets/projectImages/googledrive.png";
import BackArrow from "../../assets/BackArrow.png";
import { contentVariants } from "../variants";

const minval = "320px";

const Wrapper = styled(motion.div)`
  margin: 0 auto;
  background-color: black;
`;

const Content = styled(motion.div)`
  height: 100vh;
  overflow-y: scroll;
`;

const ProjectsContainer = styled.div`
  padding-top: 2%;
  padding-left: 2%;
  padding-right: 2%;

  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  overflow: hidden;
`;

const ProjectCard = styled.div`
  position: relative;
  display: flex;
  width: 30vw;
  height: 30vw;
  min-width: ${minval};
  min-height: ${minval};

  justify-content: center;
  align-items: center;
  background: ${(props) => (props.background ? props.background : "#5c5c5c")};
  border: ${(props) => (props.border ? props.border : "none")};
  border-radius: 5vw;
`;

const IconImg = styled.img`
  height: 100%;
  margin-left: 2%;
  max-width: 100%;
`;

const BacksideAvatar = styled.img`
  margin-top: 1%;
  height: 100%;
  border-radius: 100%;
`;

const BacksideHeader = styled.h1`
  margin-top: 7%;
  position: relative;
  font-size: calc(12px + 1.5vw);
  margin-left: 5%;

  a {
    text-decoration: underline;
    font: inherit;
    color: ${(props) => (props.linkColor ? props.linkColor : "inherit")};
    transition-duration: 0.2s;

    &:hover {
      color: ${(props) =>
        props.linkHoverColor ? props.linkHoverColor : "#5c5c5c"};
    }
  }
`;

const BacksideMidline = styled.hr`
  width: 90%;
  border: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    to right,
    ${(props) => props.from},
    ${(props) => props.to}
  );
`;

const BacksideText = styled.h1`
  margin-top: 2%;
  margin-left: 2%;
  margin-right: 2%;
  position: relative;
  font-weight: 400;
  font-size: calc(9px + 0.5vw);
`;

const BacksideSubheader = styled.h1`
  margin-top: 2%;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 0%;
  position: relative;
  font-weight: 400;
  font-size: calc(10px + 1.25vw);
`;

const colors = {
  top: ["gold", "magenta"],
  right: ["magenta", "cyan"],
  bot: ["cyan", "green"],
  left: ["#7b4397 0%, #7b4397 25%", "#dc2430 75%, #dc2430 100%"],
};

const IndexToImage = {
  tightrope: Tightrope,
  ricochet: RicochetRobots,
  sarcasm: SarcasmDetection,
  galaxyraider: GalaxyRaider,
  "zolyo.me": Logo,
  medmaps: MedMapsImage,
  covidcalc: CovidCalc,
  mysterybuglab: MysteryBugLab,
};

const IndexToIcon = {
  amazons3: AmazonS3Icon,
  nodejs: NodeJSIcon,
  python: PythonIcon,
  flask: FlaskIcon,
  tensorflow: TensorflowIcon,
  javascript: JavascriptIcon,
  css: CSSIcon,
  discord: DiscordIcon,
  html: HTMLIcon,
  react: ReactIcon,
  swift: SwiftIcon,
  mongodb: MongoDBIcon,
  mysql: MySQLIcon,
  gmail: GmailIcon,
  drive: GoogleDriveIcon,
};

//#131313
//#42083B
const ProjectCardComponent = ({
  styles: {
    background,
    backsideMidline: { bmFrom, bmTo },
    borderColor,
  },
  data: { icons, image, title, link, description, learningTargets },
}) => {
  const [isFlipped, setIsFlipped] = useState(false);
  return (
    <ReactCardFlip
      isFlipped={isFlipped}
      flipDirection="horizontal"
      containerStyle={{
        width: "30vw",
        margin: "20px",
        minWidth: minval,
        minHeight: minval,
      }}
    >
      <ProjectCard
        background={background}
        border={`solid 1px ${borderColor};`}
        onMouseEnter={
          isMobile
            ? () => {}
            : () => {
                setIsFlipped((prev) => !prev);
              }
        }
        onClick={() => {
          setIsFlipped((prev) => !prev);
        }}
      >
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* Learning Targets Icons */}
          {icons.length > 0 && (
            <div
              style={{
                position: "relative",
                width: "auto",
                height: "12%",
                marginTop: "5%",
                marginLeft: "5%",
              }}
            >
              {icons.map((e) => (
                <IconImg src={IndexToIcon[e]} />
              ))}
            </div>
          )}
          <img
            src={IndexToImage[image]}
            alt="Discord Bot"
            style={{
              position: "relative",
              width: "50%",
              height: "50%",
              left: "50%",
              top: "5%",
              transform: "translateX(-50%)",
              borderRadius: "20%",
            }}
          />
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "auto",
              textAlign: "center",
              top: "5%",
            }}
          >
            <h1 style={{ fontSize: "3.2vw" }}>{title}</h1>
          </div>
        </div>
      </ProjectCard>

      {/* Back Side */}
      <ProjectCard
        background={background}
        border={`solid 1px ${borderColor};`}
        onMouseLeave={
          isMobile
            ? () => {}
            : () => {
                setIsFlipped((prev) => !prev);
              }
        }
        onClick={() => {
          setIsFlipped((prev) => !prev);
        }}
      >
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              position: "relative",
              width: "auto",
              height: "20%",
              marginTop: "4%",
              marginLeft: "5%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <BacksideAvatar
              src={IndexToImage[image]}
              alt={title}
            ></BacksideAvatar>
            <BacksideHeader linkColor="#b3deff" linkHoverColor="#2f93e0">
              {link && (
                <a href={link} target="_blank" rel="noreferrer">
                  {title}
                </a>
              )}
              {!link && <>{title}</>}
            </BacksideHeader>
          </div>
          <div
            style={{
              position: "relative",
              width: "auto",
              height: "auto",
              top: "2%",
              marginLeft: "2%",
              marginRight: "2%",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <BacksideMidline from={bmFrom} to={bmTo}></BacksideMidline>
            <BacksideText>{description}</BacksideText>
            <BacksideMidline from={bmTo} to={bmFrom}></BacksideMidline>

            {/* Learning Targets */}

            <BacksideSubheader>Learning Targets:</BacksideSubheader>
            <BacksideText style={{ textAlign: "center" }}>
              {learningTargets
                .map((e) => (
                  <>
                    ○ &ensp; {e.target} &ensp; → &ensp; {e.desc}
                  </>
                ))
                .reduce(
                  (acc, x) =>
                    acc === null ? (
                      x
                    ) : (
                      <>
                        {acc} <br /> {x}
                      </>
                    ),
                  null
                )}
            </BacksideText>
          </div>
        </div>
      </ProjectCard>
    </ReactCardFlip>
  );
};

const Projects = () => {
  let pos = "right";

  const [isHoveringBackButton, setIsHoveringBackButton] = useState(false);
  const history = useHistory();

  return (
    <Wrapper>
      <Content
        variants={contentVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div
          style={{
            height: "25vw",
            width: "100vw",
            color: "white",
            position: "relative",
          }}
        >
          <PageName
            style={{ float: "right", top: "-2vw", position: "relative" }}
            colors={colors}
            pos={pos}
          >
            ./projects
          </PageName>
          <button
            onMouseEnter={() => {
              setIsHoveringBackButton(true);
            }}
            onMouseLeave={() => {
              setIsHoveringBackButton(false);
            }}
            onClick={() => history.goBack()}
            style={{
              background: "black",
              border: "none",
              float: "left",
              cursor: "pointer",
            }}
          >
            <img
              style={{
                width: "7vmax",
                borderRadius: "50px",
                border: isHoveringBackButton ? "solid 1px white" : "none",
                transitionDuration: "1s",
              }}
              src={BackArrow}
              alt="Go Back"
            />
          </button>
          <HorizontalBar
            borderBoth={true}
            first={true}
            colors={colors}
            pos={pos}
            style={{ position: "relative", height: "40%" }}
          ></HorizontalBar>
        </div>

        <ProjectsContainer>
          {/* Tightrope */}
          <ProjectCardComponent
            styles={{
              background: `linear-gradient(to bottom right, #5e0d05, #e35300, #f5af19)`,
              backsideMidline: {
                bmFrom: "#000428",
                bmTo: "#004e92",
              },
              borderColor: "red",
            }}
            data={{
              icons: ["nodejs", "react", "mongodb", "amazons3"],
              image: "tightrope",
              title: "Tightrope",
              description:
                "[WIP]: An application connecting hobbyist photographers to their sales with one-click-purchase portfolios.",
              learningTargets: [
                { target: "NodeJS", desc: "primary scripting language" },
                { target: "React", desc: "cutting-edge web framework" },
                {
                  target: "MongoDB",
                  desc: "user, portfolio, and linking data",
                },
                {
                  target: "Amazon S3",
                  desc: "high-res image storage & fast retrieval",
                },
              ],
            }}
          />
          {/* Sarcasm Detection */}
          <ProjectCardComponent
            styles={{
              background: `linear-gradient(to bottom right, #0F2027, #203A43, #2C5364)`,
              backsideMidline: {
                bmFrom: "#f12711",
                bmTo: "#f5af19",
              },
              borderColor: "#0096FF",
            }}
            data={{
              icons: ["python", "tensorflow", "flask", "nodejs", "react"],
              image: "sarcasm",
              title: "Sarcasm NLP",
              description:
                "A neural network trained to recognize sarcasm in news article headlines. Currently down for financial reasons -- project requires funding.",
              link: "https://github.com/azolyomi/sarcasm-nlp",
              learningTargets: [
                { target: "Python", desc: "primary scripting language" },
                {
                  target: "TensorFlow",
                  desc: "Google's Machine Learning library",
                },
                {
                  target: "Flask",
                  desc: "Python webserver hosted on Amazon EC2",
                },
                {
                  target: "NodeJS",
                  desc: "Front-end web scraping of popular news headlines",
                },
              ],
            }}
          />
          {/* Discord */}
          <ProjectCardComponent
            styles={{
              background: `linear-gradient(to bottom right, #131313, #42083B)`,
              backsideMidline: {
                bmFrom: "#743ad5",
                bmTo: "#d53a9d",
              },
              borderColor: "#851547",
            }}
            data={{
              icons: ["nodejs", "discord", "mongodb", "mysql"],
              image: "galaxyraider",
              title: "Galaxy Raider",
              description:
                "A Discord Bot serving 30000+ people, revitalizing over 70 communities in a childhood video game.",
              link: "https://github.com/azolyomi/GalaxyRaider",
              learningTargets: [
                { target: "NodeJS", desc: "primary scripting language" },
                { target: "Eris", desc: "javascript discord library" },
                { target: "MongoDB", desc: "server configs, user data" },
                { target: "MySQL", desc: "backup database" },
              ],
            }}
          />
          {/* zolyo.me */}
          <ProjectCardComponent
            styles={{
              background: "#101010",
              backsideMidline: {
                bmFrom: "magenta",
                bmTo: "gold",
              },
              borderColor: "#5c5c5c",
            }}
            data={{
              icons: ["nodejs", "react", "html", "css"],
              image: "zolyo.me",
              title: "zolyo.me",
              description: "A video-game themed portfolio website",
              link: "https://zolyo.me",
              learningTargets: [
                { target: "NodeJS", desc: "primary scripting language" },
                { target: "React", desc: "cutting-edge web framework" },
                { target: "JSX", desc: "React's unique integration of HTML" },
                { target: "CSS", desc: "modern styling for web browsers" },
              ],
            }}
          />
          {/* Ricochet Robots */}
          <ProjectCardComponent
            styles={{
              background: `linear-gradient(to bottom right, #4a0509, #7d2525)`,
              backsideMidline: {
                bmFrom: "#f12711",
                bmTo: "#f5af19",
              },
              borderColor: "#E10600",
            }}
            data={{
              icons: ["nodejs", "react"],
              image: "ricochet",
              title: "Ricochet Robots",
              description:
                "Online port for the board game, built in 24h for a hackathon. Winner of 'Best New Hack' category.",
              link: "https://zolyo.me/ricochet",
              learningTargets: [
                { target: "NodeJS", desc: "primary scripting language" },
                { target: "React", desc: "Board state updates & rendering" },
              ],
            }}
          />
          {/* MedMaps */}
          <ProjectCardComponent
            styles={{
              background: "linear-gradient(to bottom right, #080808, #000D43)",
              backsideMidline: {
                bmFrom: "#890097",
                bmTo: "#8719EF",
              },
              borderColor: "#152898",
            }}
            data={{
              icons: ["swift", "react"],
              image: "medmaps",
              title: "MedMaps",
              description:
                "An iOS app used at Duke University to teach medical students.",
              link: "https://apps.apple.com/us/app/id1519324957",
              learningTargets: [
                { target: "XCode", desc: "Apple's iOS dev toolkit" },
                { target: "Swift", desc: "XCode's native language" },
                { target: "React", desc: "cutting-edge web framework" },
                {
                  target: "AppStore",
                  desc: "How to sign, certify, and publish an app.",
                },
              ],
            }}
          />
          {/* MysteryBugLab */}
          <ProjectCardComponent
            styles={{
              background: "linear-gradient(to bottom right, #094611, #00193A)",
              backsideMidline: {
                bmFrom: "#00EF00",
                bmTo: "#0A00EF",
              },
              borderColor: "#05931A",
            }}
            data={{
              icons: ["javascript", "html", "gmail", "drive"],
              image: "mysterybuglab",
              title: "MysteryBugLab",
              description:
                "A private web service for a Brown University microbiology class to perform their final lab remotely (due to COVID-19)",
              learningTargets: [
                { target: "Javascript", desc: "primary scripting language" },
                { target: "Gmail API", desc: "used to deliver test results" },
                {
                  target: "Drive API",
                  desc: "used to dynamically store possible lab results",
                },
              ],
            }}
          />
          {/* CovidCalc */}
          <ProjectCardComponent
            styles={{
              background: "linear-gradient(to bottom right, #020000, #790000)",
              backsideMidline: {
                bmFrom: "#F90101",
                bmTo: "#F101F9",
              },
              borderColor: "#C30000",
            }}
            data={{
              icons: ["javascript", "html", "gmail", "drive"],
              image: "covidcalc",
              title: "CovidCalc",
              description:
                "A calculator for healthcare workers to determine personal protection (PPE) required for COVID-19 risk patients. Built with ID docs @Presbyterian Hospital.",
              link: "https://covidcalc.net",
              learningTargets: [
                { target: "Javascript", desc: "calculator functionality" },
                {
                  target: "Research",
                  desc: "Learned the process behind researching applications for physician use",
                },
              ],
            }}
          />
        </ProjectsContainer>
      </Content>
    </Wrapper>
  );
};

export default Projects;
