import styled from "styled-components";

export const Wrapper = styled.div`
	maxwidth: var(--maxWidth);
	background: var(--black);
	height: 100vh;
	width: 100vw;
	margin: 0 auto;
	text-align: center;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

export const TextContainer = styled.div`
	width: 100vw;
	height: 600px;
	position: absolute;
	align-self: stretch;

	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
`;

export const CenterMassiveText = styled.div`
	align-self: stretch;
	font-family: "Raleway", sans-serif;
	font-size: 6rem;
	font-weight: 700;
	color: var(--white);
`;
