import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

const colors = {
  top: ["#dba008", "#df2d8e"],
  right: ["magenta", "cyan"],
  bot: ["cyan", "green"],
  left: ["#7b4397 0%, #7b4397 25%", "#dc2430 75%, #dc2430 100%"],
};

const ProjectContentVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const Container = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const VertContainer = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
`;

const MenuContainer = styled(motion.div)`
  width: 33%;
  display: flex;
  flex-direction: row;
`;

const AbsoluteWrapper = styled(motion.div)`
  position: ${(props) => (props.enabled ? "absolute" : "relative")};
`;

const ContentContainer = styled(motion.div)`
  width: 85%;
  min-height: 300px;
  display: flex;

  ${(props) => {
    if (!props.smallDevice)
      return `
            &:before {
                content: "";
                position: absolute;
                height: 260px;
                width: 4px;
                transform: translateX(3px);
                background-color: white;
                opacity: 0.2;
                border-radius: 3px;
            }
        `;
  }}
`;

const MenuItemContainer = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
`;

const MenuText = styled(motion.text)`
  font-family: Outfit;
  font-weight: 400;
  font-size: 24px;
  color: white;
  opacity: ${(props) => (props.selected ? 0.9 : 0.6)};

  &:hover {
    opacity: ${(props) => (props.selected ? 0.9 : 0.7)};
  }
`;

const Text = styled(motion.text)`
  font-family: Outfit;
  font-size: 22px;
  font-weight: 300;
  color: #fcfafb;
  opacity: ${(props) => props.opacity ?? 1};
`;

const Heading = styled(motion.text)`
  font-family: Inter;
  font-weight: bold;
  font-size: 32px;
  color: white;
  opacity: 1;
`;

const Spacer = styled(motion.div)`
  height: ${(props) => props.height ?? 20}px;
`;
const SpanRainbow = styled(motion.span)`
  font-family: inherit;
  font-size: inherit;
  font-weight: ${(props) => {
    if (props.bold) return "500;";
    else return "inherit;";
  }};

  ${(props) => {
    let gradientColors = props.colors ?? colors["top"];
    let start = props.start ?? "bottom right";
    return `background: -webkit-linear-gradient(${start}, ${gradientColors[0]}, ${gradientColors[1]});`;
  }}
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const SpanPink = styled(motion.span)`
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: #fd71cd;
  transition-duration: 0.1s;
  cursor: pointer;

  &:hover {
    color: #e33daa;
  }
`;

const RobloxIntern = () => {
  return (
    <VertContainer
      key={"RobloxIntern"}
      variants={ProjectContentVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <Heading>Software Engineering Intern</Heading>
      <Text opacity={0.6}>June - September 2023</Text>
      <Spacer height={20} />
      <Text>
        Working as part of a small, fast-paced team building{" "}
        <SpanPink onClick={() => window.open("https://roblox.com")}>
          Roblox's
        </SpanPink>{" "}
        proof of concept for a new potential product.
      </Text>
      <Spacer height={20} />
      <Text>
        Responsible for drafting formal tech specs before implementing (and
        retaining ownership of) several critical features, including client
        facing <SpanRainbow bold>3D UI/UX</SpanRainbow> as well as necessary{" "}
        <SpanRainbow bold>privacy & security</SpanRainbow> mechanics.
      </Text>
      <Spacer height={20} />
      <Text>
        Contributed <SpanRainbow bold>2000+</SpanRainbow> lines of{" "}
        <SpanRainbow bold>Luau</SpanRainbow> and{" "}
        <SpanRainbow bold>Go</SpanRainbow> code while simultaneously iterating
        quickly on product ideas.
      </Text>
    </VertContainer>
  );
};

const DittoIntern = () => {
  return (
    <VertContainer
      key={"DittoIntern"}
      variants={ProjectContentVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <Heading>Software Engineering Intern (Mobile)</Heading>
      <Text opacity={0.6}>October 2022 - February 2023</Text>
      <Spacer height={20} />
      <Text>
        Built and released several content moderation tools that overhauled
        in-app security, including designating group admins, admin tooling, and
        in-app indications of 'verified' content for{" "}
        <SpanPink onClick={() => window.open("https://ditto-app.com")}>
          Ditto's
        </SpanPink>{" "}
        <SpanRainbow bold>React Native</SpanRainbow> mobile application.
      </Text>
      <Spacer height={20} />
      <Text>
        Authored <SpanRainbow bold>25+</SpanRainbow> pull requests contributing
        over <SpanRainbow bold>4400</SpanRainbow> lines of{" "}
        <SpanRainbow bold>JavaScript</SpanRainbow> and{" "}
        <SpanRainbow bold>React Native</SpanRainbow> code; reviewed{" "}
        <SpanRainbow bold>50+</SpanRainbow> pull requests.
      </Text>
    </VertContainer>
  );
};

const DittoEngineer = () => {
  return (
    <VertContainer
      key={"DittoEngineer"}
      variants={ProjectContentVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <Heading>Software Engineer (Part Time)</Heading>
      <Text opacity={0.6}>February 2023 - Present</Text>
      <Spacer height={20} />
      <Text>
        Leading development of <SpanRainbow bold>AI</SpanRainbow> technology for
        UX and behavioral learning. Using{" "}
        <SpanRainbow bold>stable diffusion</SpanRainbow> image generation models
        to de-homogenize default content via predictive synthesis.
      </Text>
      <Spacer height={20} />
      <Text>
        Responsible for spearheading new user stories and progressing them from
        designs to in-app features for{" "}
        <SpanPink onClick={() => window.open("https://ditto-app.com")}>
          Ditto's
        </SpanPink>{" "}
        <SpanRainbow bold>React Native</SpanRainbow> mobile application.
      </Text>
      <Spacer height={20} />
      <Text>
        Writing clean <SpanRainbow bold>JavaScript</SpanRainbow> and{" "}
        <SpanRainbow bold>React Native</SpanRainbow> code, reviewing new
        features, and architecting solutions to critical issues and bugs.
      </Text>
    </VertContainer>
  );
};

const Impact = () => {
  return (
    <VertContainer
      key={"Impact"}
      variants={ProjectContentVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <Heading>Software Engineering Intern</Heading>
      <Text opacity={0.6}>June – August 2022</Text>
      <Spacer height={20} />
      <Text>
        Led a team of <SpanRainbow bold>agile</SpanRainbow> interns to build and
        deploy a notifications service for{" "}
        <SpanPink onClick={() => window.open("https://impact.com")}>
          Impact.com
        </SpanPink>
        's partnership management application.
      </Text>
      <Spacer height={20} />
      <Text>
        Used <SpanRainbow bold>Vue.js</SpanRainbow> for the UI,{" "}
        <SpanRainbow bold>Java/Spring</SpanRainbow> and{" "}
        <SpanRainbow bold>ScyllaDB</SpanRainbow> for the backend, and{" "}
        <SpanRainbow bold>CircleCI</SpanRainbow> to automate testing and
        deployment.
      </Text>
    </VertContainer>
  );
};

const ReelayEngineer = () => {
  return (
    <VertContainer
      key={"ReelayEngineer"}
      variants={ProjectContentVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <Heading>Full-Stack Software Engineer</Heading>
      <Heading style={{ fontSize: 20 }}>(iOS / Android)</Heading>
      <Text opacity={0.6}>January 2022 - October 2022</Text>
      <Spacer height={20} />
      <Text>
        Working as part of a small team of engineers to build a modern film
        review{" "}
        <SpanPink onClick={() => window.open("https://reelay.app")}>
          iOS application
        </SpanPink>{" "}
        using <SpanRainbow bold>React Native</SpanRainbow>,{" "}
        <SpanRainbow bold>Node.js</SpanRainbow>,{" "}
        <SpanRainbow bold>Express.js</SpanRainbow> &{" "}
        <SpanRainbow bold>PostgreSQL</SpanRainbow>.
      </Text>
      <Spacer height={20} />
      <Text>
        Designing and programming core, user-facing{" "}
        <SpanRainbow bold>UI/UX</SpanRainbow> features including in-app settings
        and profile modules, discovery-oriented home screen, animations module,
        and <SpanRainbow bold>Next.js</SpanRainbow> web VM.
      </Text>
    </VertContainer>
  );
};

const ReelayIntern = () => {
  return (
    <VertContainer
      key={"ReelayIntern"}
      variants={ProjectContentVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <Heading>Software Engineering Intern</Heading>
      <Text opacity={0.6}>October 2021 - January 2022</Text>
      <Spacer height={20} />
      <Text>
        Interned with a focus on feature development and implementation in a
        small, <SpanRainbow bold>agile</SpanRainbow> core team of 4 engineers
      </Text>
      <Spacer height={20} />
      <Text>
        Gained experience with <SpanRainbow bold>React Native</SpanRainbow> and
        the <SpanRainbow bold>Node.js</SpanRainbow> /{" "}
        <SpanRainbow bold>Express.js</SpanRainbow> /{" "}
        <SpanRainbow bold>PostgreSQL</SpanRainbow> tech stack.
      </Text>
    </VertContainer>
  );
};

export default function ExperienceMenu({ colors }) {
  const [selectedItem, setSelectedItem] = useState(0);
  const [renderAnimation, setRenderAnimation] = useState(true);

  const smallDevice = isMobile || window.innerWidth < 800;

  // [ Name, Component ]
  const Experiences = [
    ["Roblox - Intern", RobloxIntern],
    ["Ditto - Engineer", DittoEngineer],
    ["Ditto - Intern", DittoIntern],
    ["Reelay - Engineer", ReelayEngineer],
    ["Impact.com", Impact],
    ["Reelay - Intern", ReelayIntern],
  ];

  const GradientBar = styled(motion.div)`
    height: 30px;
    width: 4px;
    ${(props) =>
      props.selected
        ? `background: linear-gradient(to bottom, ${colors[0]}, ${colors[1]})`
        : ""};
    border-radius: 2px;
    margin-right: ${(props) => (props.selected ? `20px` : "0px")};
    ${(props) =>
      props.selected ? "animation: animateExperience 0.2s ease-out;" : ""}

    @keyframes animateExperience {
      0% {
        margin-right: 0px;
        background: none;
      }
      20% {
        margin-right: 4px;
        background: linear-gradient(to bottom, ${colors[0]}, ${colors[1]});
      }
      to {
        margin-right: 20px;
        background: linear-gradient(to bottom, ${colors[0]}, ${colors[1]});
      }
    }
  `;

  const MenuTabItem = ({ id, text }) => {
    const selected = selectedItem === id;
    const handleClick = () => {
      setRenderAnimation(false);
      setSelectedItem(id);
      setRenderAnimation(true);
    };
    return (
      <MenuItemContainer onClick={handleClick}>
        {selected && <GradientBar selected={selected} />}
        <MenuText selected={selected}>{text}</MenuText>
      </MenuItemContainer>
    );
  };

  return (
    <Container>
      <MenuContainer>
        <AbsoluteWrapper>
          {Experiences.map((item, index) => (
            <MenuTabItem key={index} id={index} text={item[0]} />
          ))}
        </AbsoluteWrapper>
      </MenuContainer>
      <ContentContainer smallDevice={smallDevice}>
        {renderAnimation && Experiences[selectedItem][1]()}
      </ContentContainer>
    </Container>
  );
}
