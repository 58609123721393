import { Wrapper, Content } from "./About/About.styles";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import InstagramIcon from "../../assets/projectImages/instagram.png";
import GithubIcon from "../../assets/projectImages/github.svg";
import TwitterIcon from "../../assets/projectImages/twitter.png";
import LinkedInIcon from "../../assets/contactImages/linkedin.png"
import ContactBackArrow from "../../assets/contactImages/contactBackArrow.png"
import { useHistory } from "react-router-dom";

import { motion } from "framer-motion";
import { contentVariants } from '../variants';

const HEROTOFU_URL = process.env.REACT_APP_HEROTOFU_URL;

const Spacer = styled.div`
	height: ${props => props.height ?? 20}px;
	scroll-snap-align: none;
`

const colors = {
	top: ["gold", "magenta"],
	right: ["magenta", "cyan"],
	bot: ["cyan", "green"],
	left: ["#7b4397 0%, #7b4397 25%", "#dc2430 75%, #dc2430 100%"],
};

const PageName = styled(motion.h1)`
	position: relative;
	height: 100%;
    font-size: 5.8vmax;
	margin-right: 2vw;
	${(props) => {
		return `background-image: linear-gradient(to bottom right, ${colors[props.pos][0]}, ${
			colors[props.pos][1]
		});`;
	}}
	background-clip: text;
	text-fill-color: transparent;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
`;

const Container = styled.div`
	height: 100vh;
	width: 100vw;
	background: var(--black);
    z-index: 100;
	position: relative;
    overflow-y: scroll;
`;

const HeaderBar = styled.div`
    padding-left: 30px;
	width: 100%;
	height: 16%;
    min-height: 100px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
    background-color: black;
    z-index: 200;
`

const BackArrow = styled.img`
    height: 50%;
	object-fit: cover;
    transition-duration: 0.2s;
    cursor: pointer;

    &:hover {
        transform: scale(1.2);
    }
`

const Contact = (props) => {
    const history = useHistory();

    return (
        <Wrapper style={{height: "100vh", width: '100vw', background: "black"}}>
            <Content variants={contentVariants} initial="hidden" animate="visible" exit="exit">
                <Container>
                    <HeaderBar>
                        <BackArrow onClick={() => history.goBack()} style={{zIndex: 100}} src={ContactBackArrow} />
                       <div>
                        <PageName pos={'left'}>./contact</PageName>
                       </div>
                    </HeaderBar>
                    <ContactElem />
                </Container>
            </Content>
        </Wrapper>
    )
}

const ContactFormPositionalContainer = styled.div`
    width: 100%;
    height: 84%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ContactFormContainer = styled.div`
    width: 60%;
    height: 75%;
    min-height: 600px;
    min-width: 315px;
    background: #0d0d0d;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &::after {
        position: absolute;
        top: -1px; bottom: -1px;
        left: -1px; right: -1px;
        background: linear-gradient(to top left, #D61B1B, #6522BA);
        content: '';
        z-index: -1;
        border-radius: 10px;
        opacity: 0.9;
    }
`

const InputsContainer = styled.div`
    width: 90%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Label = styled.label`
    font-size: 1.3rem;
    color: white;
    align-self: flex-start;
    margin-bottom: 10px;
    margin-left: 5px;
`


const TextInput = styled.input`
    width: 100%;
    padding: 20px;
    font-size: 1rem;
    outline: none;
    border: none;
    border-radius: 15px;
    transition-duration: 0.3s;
    background: #1d1d1d;
    color: white;
`

const TextArea = styled.textarea`
    width: 100%;
    padding: 20px;
    font-size: 1rem;
    outline: none;
    border: none;
    border-radius: 15px;
    resize: vertical;
    max-height: 300px;
    min-height: 100px;
    transition-duration: 0.3s;
    background: #1d1d1d;
    color: white;
`

const Submit = styled.input`
    width: 200px;
    padding: 15px;
    font-size: 22px;
    font-weight: bold;
    border-radius: 10px;
    background-color: ${props => props.disabled ? '#1d1d1d' : '#951937'};
    color: white;
    opacity: ${props => props.disabled ? '0.7' : '1'};
    outline: none;
    border: none;
    cursor: ${props => props.disabled ? 'auto' : 'pointer'};

    transition-duration: 0.3s;

    &:hover {
        background-color: ${props => props.disabled ? '#1d1d1d' : '#b21f4d'};
    }
`

const SpanColor = styled.span`
	font-family: inherit;
	font-size: inherit;	
	font-weight: inherit;

	background: -webkit-linear-gradient(#D61B1B, #6522BA);
	background-clip: text;
	text-fill-color: transparent;
	-webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`

const SocialImage = styled.img`
    height: 60px;
    object-fit: contain;
    transition-duration: 0.2s;
    cursor: pointer;

    &:hover {
        transform: scale(1.2);
    }
`

const SocialBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
`


const ContactElem = () => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const onSubmit = () => {
        console.log(email, message);
    }
    return (
        <ContactFormPositionalContainer>
            <ContactFormContainer>
                <SocialBar>
                    <SocialImage style={{height: 55}} src={LinkedInIcon}  onClick={() => window.open("https://linkedin.com/in/azolyomi", "_blank")}/>
                    <SocialImage style={{marginLeft: 5}}src={GithubIcon}  onClick={() => window.open("https://github.com/azolyomi", "_blank")}/>
                    <SocialImage src={InstagramIcon} onClick={() => window.open("https://www.instagram.com/andras.zolyomi/", "_blank")}/>
                    <SocialImage style={{height: 50, marginLeft: 8}} src={TwitterIcon}  onClick={() => window.open("https://twitter.com/AndrasZolyomi", "_blank")}/>
                </SocialBar>
                <form style={{width: '100%', height: '84%', display: 'flex', justifyContent: 'center', alignItems: 'center'}} onSubmit={onSubmit} action={HEROTOFU_URL} method="post">
                    <InputsContainer >
                        <Label>email <SpanColor>*</SpanColor></Label>
                        <TextInput required placeholder="john.doe@gmail.com" type="email" name="Email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        <Spacer height={40} />
                        <Label>message (500 chars max) <SpanColor>*</SpanColor></Label>
                        <TextArea required placeholder="Get in touch..."rows={5} maxLength={500} name="Message" id="message" type="text" value={message} onChange={(e) => setMessage(e.target.value)} />
                        <Spacer height={40} />
                        <Submit type="submit" value="submit" disabled={!email || !message}/>
                    </InputsContainer>
                </form>
            </ContactFormContainer>
        </ContactFormPositionalContainer>
    )
}

export default Contact;