import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const Wrapper = styled(motion.div)`
  maxwidth: var(--maxWidth);
  background: var(--black);
  margin: 0 auto;
  overflow: auto;
`;

const Content = styled(motion.div)`
  overflow: auto;
`;

const Container = styled(motion.div)`
  height: 100vh;
  width: 100vw;
  background: var(--black);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Monkey = () => {
  return (
    <Wrapper>
      <Content>
        <Container>
          <h1 style={{ fontWeight: "bold", textAlign: "center" }}>
            Things look different from up here
          </h1>
        </Container>
      </Content>
    </Wrapper>
  );
};

export default Monkey;
