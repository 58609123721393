import React, { useState } from 'react'
import { motion } from "framer-motion";
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

const colors = {
	top: ["#dba008", "#df2d8e"],
	right: ["magenta", "cyan"],
	bot: ["cyan", "green"],
	left: ["#7b4397 0%, #7b4397 25%", "#dc2430 75%, #dc2430 100%"],
};

const ProjectContentVariants = {
	hidden: {
		opacity: 0,
	},
	visible: {
		opacity: 1,
		transition: {
			duration: 0.5,
		},
	},
    exit: {
        opacity: 0,
        transition: {
            duration: 0.5,
        },
    }
};

const Container = styled(motion.div)`
    width: 100%;
    display: flex;
    flex-direction: row;
`

const VertContainer = styled(motion.div)`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 30px;
`

const MenuContainer = styled(motion.div)`
    width: 33%;
    display: flex;
    flex-direction: row;
`

const AbsoluteWrapper = styled(motion.div)`
    position: ${props => props.enabled ? "absolute" : "relative"};
`

const ContentContainer = styled(motion.div)`
    width: 85%;
    min-height: 250px;
    display: flex;

    ${props => {
        if (!props.smallDevice) return `
            &:before {
                content: "";
                position: absolute;
                height: 230px;
                width: 4px;
                transform: translateX(3px);
                background-color: white;
                opacity: 0.2;
                border-radius: 3px;
            }
        `
    }}
`

const MenuItemContainer = styled(motion.div)`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
`

const MenuText = styled(motion.text)`
    font-family: Outfit;
    font-weight: 400;
    font-size: 24px;
    color: white;
    opacity: ${props => props.selected ? 0.9 : 0.6};

    &:hover {
        opacity: ${props => props.selected ? 0.9 : 0.7};
    }
`

const Text = styled(motion.text)`
	font-family: Outfit;
	font-size: 22px;
	font-weight: 300;
	color: #FCFAFB;
	opacity: ${props => props.opacity ?? 1};
`

const Heading = styled(motion.text)`
	font-family: Inter;
	font-weight: bold;
	font-size: 32px;
	color: white;
	opacity: 1;
`

const Spacer = styled(motion.div)`
	height: ${props => props.height ?? 20}px;
`
const SpanRainbow = styled(motion.span)`
	font-family: inherit;
	font-size: inherit;	
	font-weight: ${props => {
        if (props.bold) return "500;"
        else return "inherit;"
    }};

	${props => {
		let gradientColors = props.colors ?? colors["top"];
		let start = props.start ?? "bottom right";
		return `background: -webkit-linear-gradient(${start}, ${gradientColors[0]}, ${gradientColors[1]});`;
	}}
	background-clip: text;
	text-fill-color: transparent;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
`

const GalaxyRaider = () => {
    return (
        <VertContainer key={"GalaxyRaider"} variants={ProjectContentVariants} initial="hidden" animate="visible" exit="exit">
            <Heading>
                RoTMG Discord Bot
            </Heading>
            <Spacer height={20} />
            <Text>A paid Discord service serving <SpanRainbow bold>70k+</SpanRainbow> members in over 70 communities.</Text>
            <Spacer height={20} />
            <Text>Provides large-scale infrastructure for user membership, organization, and regulation with modern <SpanRainbow bold>NodeJS</SpanRainbow>, Eris Discord, and <SpanRainbow bold>MongoDB</SpanRainbow> </Text>
        </VertContainer>
    )
}

const SarcasmNLP = () => {
    return (
        <VertContainer key={"SarcasmNLP"} variants={ProjectContentVariants} initial="hidden" animate="visible" exit="exit">
            <Heading>
                AI-Powered Sarcasm Detection
            </Heading>
            <Spacer height={20} />
            <Text>A proof-of-concept API resource that analyzes news headlines and ranks them based on the level of sarcasm detected.</Text>
            <Spacer height={20} />
            <Text>Neural Network built with <SpanRainbow bold>TensorFlow</SpanRainbow>, aided by Stanford's repository of glove vectors. API built with <SpanRainbow bold>Flask</SpanRainbow> and served through <SpanRainbow bold>AWS</SpanRainbow>.</Text>
        </VertContainer>
    )
}

const MedMaps = () => {
    return (
        <VertContainer key={"MedMaps"} variants={ProjectContentVariants} initial="hidden" animate="visible" exit="exit">
            <Heading>
                MedMaps iOS App
            </Heading>
            <Spacer height={20} />
            <Text>A medical teaching mobile application that maps symptoms to their causes for active recall studying purposes.</Text>
            <Spacer height={20} />
            <Text>Initially built with <SpanRainbow bold>XCode</SpanRainbow> and <SpanRainbow bold>Swift</SpanRainbow> before being ported to <SpanRainbow bold>React Native</SpanRainbow> in 2022.</Text>
        </VertContainer>
    )
}

export default function FeaturedProjectsMenu({ colors }) {
    const [selectedItem, setSelectedItem] = useState(0);
    const [renderAnimation, setRenderAnimation] = useState(true);

    const smallDevice = (isMobile || window.innerWidth < 800);

    // [ Name, Component ]
    const MenuItems = [
        ["Galaxy Raider", GalaxyRaider], 
        ["Sarcasm NLP", SarcasmNLP], 
        ["MedMaps", MedMaps]
    ];

    const GradientBar = styled(motion.div)`
        height: 30px;
        width: 4px;
        ${props => props.selected ? `background: linear-gradient(to bottom, ${colors[0]}, ${colors[1]})` : ""};
        border-radius: 2px;
        margin-right: ${props => props.selected ? `20px` : "0px"};
        ${props => props.selected ? "animation: animate 0.2s ease-out;" : ""}
        
        @keyframes animate {
            0% {
                margin-right: 0px;
                background: none;
            }
            20% {
                margin-right: 4px;
                background: linear-gradient(to bottom, ${colors[0]}, ${colors[1]});
            }
            to {
                margin-right: 20px;
                background: linear-gradient(to bottom, ${colors[0]}, ${colors[1]});
            }
        }
    `

    const MenuTabItem = ({ id, text }) => {
        const selected = (selectedItem === id);
        const handleClick = () => {
            setRenderAnimation(false);
            setSelectedItem(id);
            setRenderAnimation(true);
        }
        return (
            <MenuItemContainer onClick={handleClick}>
                { selected && <GradientBar selected={selected}/> }
                <MenuText selected={selected}>{text}</MenuText>
            </MenuItemContainer>
        )
    }

  return (
    <Container>
        <MenuContainer>
            <AbsoluteWrapper>
                {MenuItems.map((item, index) => (
                    <MenuTabItem key={index} id={index} text={item[0]} />
                ))}
            </AbsoluteWrapper>
        </MenuContainer>
        <ContentContainer smallDevice={smallDevice}>
            { renderAnimation && MenuItems[selectedItem][1]() }
        </ContentContainer>
    </Container>
  )
}
