import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    :root {
        --maxWidth: 1280px;
        --white: #fff;
        --lightGrey: #666666;
        --darkGrey: #141414;
        --grey: #292929;
        --black: #000000;
        --fontXL: 2.5rem;
        --fontL: 2rem;
        --fontM: 1.5rem;
        --fontS: 1rem;
    }

    * {
        box-sizing: border-box;
        font-family: 'Comfortaa', cursive;
        // font-family: 'Source Code Pro', monospace;
        div::-moz-selection { background:transparent; }
        div::selection { background:transparent; }
        overscroll-behavior: none;
    }

    


    
    body, html { 
        margin: 0;
        padding: 0;
        overflow: hidden;

        h1 { 
            font-size: 2rem;
            font-weight: 700;
            color: var(--white)
        }

        h3 {
            font-size: 1.1rem;
            font-weight: 500;
        }

        p {
            font-weight: 300;
            font-size: 1rem;
            color: var(--white);
        }
    }

    .glitch {
        text-decoration: none;
        text-transform: uppercase;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
        color: #fff;
        letter-spacing: 5px;
        
        &:before, &:after {
            display: block;
            content: attr(data-glitch);
            text-transform: uppercase;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            opacity: .8;
        } &:after {
            color: #f0f;
            z-index: -2;
        } &:before {
            color: #0ff;
            z-index: -1;
        }
        &:hover {
            &:before {
                animation: glitch .3s cubic-bezier(.25, .46, .45, .94) both 5
            }
            &:after {
                animation: glitch .3s cubic-bezier(.25, .46, .45, .94) reverse both 5
            }
        }
    }
    @keyframes glitch {
        0% {
            transform: translate(0);
        }
        20% {
            transform: translate(-5px, 5px);
        }
        40% {
            transform: translate(-5px, -5px);
        }
        60% {
            transform: translate(5px, 5px);
        }
        80% {
            transform: translate(5px, -5px);
        }
        to {
            transform: translate(0);
        }
    }
`;
