import React, { useState } from "react";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
// import Particles from "react-tsparticles";

import {
	Wrapper,
	Content,
	OuterCircle,
	JoystickContainer,
	InnerCircle,
	ExpandArrowImage,
	NavigationArrowImage,
	NavLink,
	NavCenterContainer,
	PointerImage
} from "./Joystick.styles";

import ExpandArrow from "../../assets/Expand-Arrow.png";
import Pointer from "../../assets/pointer.png";
import { contentVariants } from "../variants"; 

const svgVariants = {
	hidden: {
		opacity: 0,
	},
	visible: {
		opacity: 1,
		transition: {
			duration: 1,
		},
	},
};

const pathVariants = {
	hidden: {
		opacity: 0,
		pathLength: 0,
	},
	visible: {
		opacity: 1,
		pathLength: 1,
		transition: {
			duration: 1,
			ease: "easeInOut",
		},
	},
};

const SvgCircleComponent = ({ innerCircleWidth, isVisible, strokeWidth }) => {
	const circle = function (cx, cy, r, deg) {
		var theta = (deg * Math.PI) / 180,
			dx = r * Math.cos(theta),
			dy = -r * Math.sin(theta);
		return (
			"M " +
			cx +
			" " +
			cy +
			"m " +
			dx +
			"," +
			dy +
			"a " +
			r +
			"," +
			r +
			" 0 1,0 " +
			-2 * dx +
			"," +
			-2 * dy +
			"a " +
			r +
			"," +
			r +
			" 0 1,0 " +
			2 * dx +
			"," +
			2 * dy
		);
	};
	return (
		<motion.svg
			height={innerCircleWidth}
			width={innerCircleWidth}
			variants={svgVariants}
			initial="hidden"
			animate={isVisible ? "visible" : "hidden"}
		>
			<linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
				<stop offset="0%" stop-color="#05a" />
				<stop offset="100%" stop-color="#0a5" />
			</linearGradient>
			<motion.path
				variants={pathVariants}
				d={circle(innerCircleWidth / 2, innerCircleWidth / 2, innerCircleWidth / 2, 90)}
				fill="transparent"
				stroke="url(#linear)"
				strokeWidth={strokeWidth}
			/>
		</motion.svg>
	);
};

const linkPaths = {
	top: "about",
	right: "projects",
	bot: "resume",
	left: "contact",
};

const colors = {
	top: ["gold", "magenta"],
	right: ["magenta", "cyan"],
	bot: ["cyan", "green"],
	left: ["#7b4397 0%, #7b4397 25%", "#dc2430 75%, #dc2430 100%"],
};

function calcDistance(p1, p2) {
	return Math.pow(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2), 1/2);
}

const Home = () => {
	const history = useHistory();
	const [isHovering, setIsHovering] = useState(false);
	const [joystickIsClicked, setJoystickIsClicked] = useState(false);
	const [hoveredLink, setHoveredLink] = useState("");
	const winDimensions = useWindowDimensions();

	var draggingTowards = "";
	var prevDraggingTowards = "";
	var leftDist, rightDist, topDist, botDist;

	const joystickWidth = 90;
	const outerCircleWidth = 600;
	const innerCircleWidth = 550;

	var centerX = winDimensions.width * 0.5;
	var centerY = winDimensions.height * 0.5;

	var rightLinkPos = {x: centerX + outerCircleWidth/2, y: centerY}
	var leftLinkPos = {x: centerX - outerCircleWidth/2, y: centerY}
	var topLinkPos = {x: centerX, y: centerY - outerCircleWidth/2}
	var botLinkPos = {x: centerX, y: centerY + outerCircleWidth/2}


	return (
		<Wrapper>
			<motion.div variants={contentVariants} initial="hidden" animate="visible" exit="exit">
				<Content>
					<OuterCircle width={outerCircleWidth} isHovering={isHovering}>
						<NavCenterContainer>
							<NavLink
								pos="top"
								visible={joystickIsClicked}
								parentWidth={outerCircleWidth}
								selected={hoveredLink === linkPaths.top ? true : false}
								whileHover={{ scale: 1.2 }}
								colors={colors}
							>
								about
							</NavLink>
							<NavLink
								pos="bot"
								visible={joystickIsClicked}
								parentWidth={outerCircleWidth}
								selected={hoveredLink === linkPaths.bot ? true : false}
								colors={colors}
							>
								resume
							</NavLink>
							<NavLink
								pos="left"
								visible={joystickIsClicked}
								parentWidth={outerCircleWidth}
								selected={hoveredLink === linkPaths.left ? true : false}
								colors={colors}
							>
								contact
							</NavLink>
							<NavLink
								pos="right"
								visible={joystickIsClicked}
								parentWidth={outerCircleWidth}
								selected={hoveredLink === linkPaths.right ? true : false}
								colors={colors}
							>
								projects
							</NavLink>
						</NavCenterContainer>
						{/* Arrows visible once clicked: */}
						<NavigationArrowImage
							src={ExpandArrow}
							rot="top"
							width={outerCircleWidth * 0.05}
							visible={joystickIsClicked}
						/>
						<NavigationArrowImage
							src={ExpandArrow}
							rot="right"
							width={outerCircleWidth * 0.05}
							visible={joystickIsClicked}
						/>
						<NavigationArrowImage
							src={ExpandArrow}
							rot="bot"
							width={outerCircleWidth * 0.05}
							visible={joystickIsClicked}
						/>
						<NavigationArrowImage
							src={ExpandArrow}
							rot="left"
							width={outerCircleWidth * 0.05}
							visible={joystickIsClicked}
						/>
						<InnerCircle
							width={innerCircleWidth}
							isHovering={isHovering}
							joystickIsClicked={joystickIsClicked}
						>

							<SvgCircleComponent
								innerCircleWidth={innerCircleWidth}
								isVisible={isHovering}
								strokeWidth={2}
							/>
							<PointerImage
									src={Pointer}
									width={joystickWidth * 0.4}
									visible={!isHovering}>
							</PointerImage>
							<JoystickContainer
								layout
								drag
								dragElastic={0}
								width={joystickWidth}
								dragConstraints={{
									top: -innerCircleWidth / 2,
									left: -innerCircleWidth / 2,
									right: innerCircleWidth / 2,
									bottom: innerCircleWidth / 2,
								}}
								whileHover={{
									scale: 1.2,
									cursor: "pointer",
									backgroundImage:
										"linear-gradient(black, black), linear-gradient(to bottom right, cyan, magenta)",
								}}
								onHoverStart={(e) => {
									console.log(e);
									setIsHovering(true);
								}}
								onHoverEnd={() => {
									setIsHovering(false);
									setJoystickIsClicked(false);
								}}
								onDragStart={(event, info) => {
									setJoystickIsClicked(true);
								}}
								onTapStart={() => {
									setIsHovering(true); // added for mobile
									setJoystickIsClicked(true);
								}}
								onDragEnd={(event, info) => {
									setJoystickIsClicked(false);
									if (hoveredLink !== "") {
										setIsHovering(false); // added for mobile
										history.push(`/${hoveredLink}`);
									}
								}}
								onDrag={(event, info) => {
									leftDist = calcDistance(info.point, leftLinkPos)
									rightDist = calcDistance(info.point, rightLinkPos)
									topDist = calcDistance(info.point, topLinkPos)
									botDist = calcDistance(info.point, botLinkPos)

									console.log("TOP: ", topDist);
									console.log("BOT: ", botDist);
									console.log("LEFT: ", leftDist);
									console.log("RIGHT: ", rightDist);
									
									let min = Math.min(leftDist, rightDist, topDist, botDist)

									if (min > 250) setHoveredLink("")
									else {
										if (min === rightDist) draggingTowards = linkPaths.right;
										else if (min === leftDist) draggingTowards = linkPaths.left;
										else if (min === botDist) draggingTowards = linkPaths.bot;
										else draggingTowards = linkPaths.top;

										if (draggingTowards !== prevDraggingTowards) {
											// only update on a change of directional dragging
											setHoveredLink(draggingTowards);
											prevDraggingTowards = draggingTowards;
										}
									}
									
								}}
							>
								{/* Guide arrows visible on hover: */}
								
								<ExpandArrowImage
									src={ExpandArrow}
									rot="top"
									width={joystickWidth * 0.2}
									visible={joystickIsClicked ? false : isHovering}
								/>
								<ExpandArrowImage
									src={ExpandArrow}
									rot="right"
									width={joystickWidth * 0.2}
									visible={joystickIsClicked ? false : isHovering}
								/>
								<ExpandArrowImage
									src={ExpandArrow}
									rot="bot"
									width={joystickWidth * 0.2}
									visible={joystickIsClicked ? false : isHovering}
								/>
								<ExpandArrowImage
									src={ExpandArrow}
									rot="left"
									width={joystickWidth * 0.2}
									visible={joystickIsClicked ? false : isHovering}
								/>
							</JoystickContainer>
						</InnerCircle>
					</OuterCircle>
				</Content>
			</motion.div>
		</Wrapper>
	);
};

export default Home;
/**
 * <TextContainer
						drag
						dragConstraints={{
							top: -50,
							left: -50,
							right: 50,
							bottom: 50,
						}}
						whileHover={{
							color: "transparent",
							borderStyle: "solid",
							borderColor: "transparent",
							borderWidth: "2px",
							borderRadius: "75px",
							width: "150px",
							height: "150px",
							fontSize: "1rem",
							backgroundImage:
								"linear-gradient(black, black), linear-gradient(to bottom right, magenta, gold)",
							backgroundOrigin: "border-box",
							backgroundClip: "content-box, border-box",
						}}
						initial={{ color: "var(--white)" }}
					>
						andras zolyomi
					</TextContainer>
 */

/**
					 * <Particles
					id="tsparticles"
					options={{
						fpsLimit: 60,
						interactivity: {
							detectsOn: "canvas",
							events: {
								onClick: {
									enable: true,
									mode: "push",
								},
								onHover: {
									enable: true,
									mode: "grab",
								},
								resize: true,
							},
							modes: {
								push: {
									quantity: 4,
								},
								grab: {
									distance: 100,
									lineLinked: {
										opacity: 1,
									},
								},
							},
						},
						particles: {
							color: {
								value: "#ffffff",
							},
							links: {
								color: "#ffffff",
								distance: 150,
								enable: true,
								opacity: 0.5,
								width: 1,
							},
							collisions: {
								enable: true,
							},
							move: {
								direction: "none",
								enable: true,
								outMode: "bounce",
								random: false,
								speed: 1,
								straight: false,
							},
							number: {
								density: {
									enable: true,
									value_area: 800,
								},
								value: 50,
							},
							opacity: {
								value: isHovering ? 0 : 0.5,
							},
							shape: {
								type: "circle",
							},
							size: {
								random: true,
								value: 5,
							},
						},
						detectRetina: true,
					}}
				/>
					 */
