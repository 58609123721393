import React from "react";
import { motion } from "framer-motion";

import { Wrapper, Content, CenterMassiveText, TextContainer } from "./NotFound.styles";
import {contentVariants} from '../variants';

const NotFound = () => {
	return (
		<Wrapper>
			<motion.div variants={contentVariants} initial="hidden" animate="visible" exit="exit">
				<Content>
					<TextContainer>
						<CenterMassiveText>
							<div class="glitch" data-glitch="Page Not Found">
								Page Not Found
							</div>
						</CenterMassiveText>
					</TextContainer>
				</Content>
			</motion.div>
		</Wrapper>
	);
};

export default NotFound;
