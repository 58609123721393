//Styles
import { GlobalStyle } from "./GlobalStyle";

//Routing
import { Switch, Route, useLocation } from "react-router-dom";

//Animation
import { AnimatePresence } from "framer-motion";

//Home
import Joystick from "./components/Home/Joystick";
//about
import { About } from "./components/Pages/About/About";
//resume
import Resume from "./components/Pages/Resume";
//research
import Research, { MSTPaperComponent } from "./components/Pages/Research";
//projects
import Projects from "./components/Pages/Projects";
//contact
import Contact from "./components/Pages/Contact";

// SarcasmProject
import Sarcasm from "./components/Pages/sarcasm";

// RicochetGame
import RicochetGame from "./components/Pages/ricochet/components/Home/Home";

// Reuben Birth 2023
import ReubenBirth2023 from "./components/Pages/reubenbirth2023";
import ReubenBirth2023Solution from "./components/Pages/reubenbirth2023/monkey";

//Not Found
import NotFound from "./components/NotFound/NotFound";

const App = () => {
  const location = useLocation();
  return (
    <>
      {/* <Header /> */}
      <AnimatePresence>
        <Switch location={location} key={location.pathname}>
          <Route exact path="/" component={About} />
          <Route path="/about" component={About} />
          <Route path="/joystick" component={Joystick} />
          <Route path="/resume" component={Resume} />
          <Route exact path="/research" component={Research} />
          <Route path="/research/mst" component={MSTPaperComponent} />
          <Route path="/projects" component={Projects} />
          <Route path="/contact" component={Contact} />
          <Route path="/sarcasm" component={Sarcasm} />
          <Route path="/ricochet" component={RicochetGame} />
          <Route path="/reubenbirth2023" component={ReubenBirth2023} />
          <Route path="/monkey" component={ReubenBirth2023Solution} />
          <Route component={NotFound} />
        </Switch>
        <GlobalStyle />
      </AnimatePresence>
    </>
  );
};

export default App;
