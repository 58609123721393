import styled from "styled-components";
import { motion } from "framer-motion";

export const Wrapper = styled(motion.div)`
	maxwidth: var(--maxWidth);
	background: var(--black);
	margin: 0 auto;
`;

export const Content = styled(motion.div)``;

export const Container = styled(motion.div)`
	height: calc(400vmin + 240px);
	background: var(--black);
	margin: 0 auto;
	overflow: hidden;
	position: relative;
	margin-bottom: 0;
`;

export const ShortContainer = styled(motion.div)`
	height: 100vh;
	background: var(--black);
	margin: 0 auto;
	overflow: hidden;
	position: relative;
	margin-bottom: 0;
`;

export const ShortMedContainer = styled(motion.div)`
	height: 140vh;
	background: var(--black);
	margin: 0 auto;
	display: block;
	overflow: hidden;
	position: absolute;
	margin-bottom: 0;
`

export const MedContainer = styled(motion.div)`
	height: 260vh;
	background: var(--black);
	margin: 0 auto;
	display: block;
	overflow: hidden;
	position: absolute;
	margin-bottom: 0;
`;

export const PageName = styled(motion.h1)`
	position: absolute;
	right: 5vw;
	top: -3vw;
	font-size: 7vmax;
	${(props) => {
		let colors = props.colors;
		return `background-image: linear-gradient(to bottom right, ${colors[props.pos][0]}, ${
			colors[props.pos][1]
		});`;
	}}
	background-clip: text;
	text-fill-color: transparent;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
`;

export const CardOne = styled(motion.div)`
	background-color: #1e1e1e;
	border: solid 1px var(--lightGrey);
	width: 60vw;
	height: auto;
	${(props) => {
		if (props.left) return `left: 5vw;`;
		else return `right: 5vw;`;
	}}

	top: ${(props) => (props.top ? props.top : "35vmax")};
	position: absolute;
	z-index: 10;
	border-radius: 10px;
	transition-duration: 0.2s;
	-webkit-transition-duration: 0.2s;

	@media only screen and (max-width: 600px) {
		top: 30vmax;
	}
	@media only screen and (max-width: 550px) {
		top: 28vmax;
	}
	@media only screen and (max-width: 450px) {
		top: 26vmax;
	}
	@media only screen and (max-width: 400px) {
		top: 24vmax;
	}
	@media only screen and (max-width: 350px) {
		top: 22vmax;
	}
`;

export const CardTwo = styled(motion.div)`
	background-color: #2c2c2c;
	border: solid 1px var(--lightGrey);
	width: 60vw;
	height: auto;
	${(props) => {
		if (props.left) return `left: 5vw;`;
		else return `right: 5vw;`;
	}}

	top: ${(props) => (props.top ? props.top : "120vmax")};
	z-index: 10;
	position: absolute;
	border-radius: 10px;
	transition-duration: 0.2s;
	-webkit-transition-duration: 0.2s;

	@media only screen and (max-width: 600px) {
		top: 105vmax;
	}
	@media only screen and (max-width: 550px) {
		top: 103vmax;
	}
	@media only screen and (max-width: 450px) {
		top: 101vmax;
	}
	@media only screen and (max-width: 400px) {
		top: 99vmax;
	}
	@media only screen and (max-width: 350px) {
		top: 97vmax;
	}
`;

export const BackButton = styled(motion.div)`
	display: flex;
	position: relative;
	top: 160vmax;
	width: auto;
	maxwidth: 500px;
	cursor: pointer;
	left: 5vw;
	img {
		position: relative;
		float: left;
		height: 20vmin;
		border-radius: 150px;
	}
	h1 {
		font-size: 8vmin;
		position: relative;
		float: right;
		margin-left: 10px;
	}

	@media only screen and (max-width: 600px) {
		top: 170vmax;
	}
`;

const DiagonalBar = styled(motion.div)`
	height: 10vw;
	background: var(--grey);
	position: relative;
`;

export const RegularDiagonalBar = styled(DiagonalBar)`
	width: 120vw;
	left: -7vw;

	transform: rotate(23deg);
	${(props) => {
		if (props.first)
			return `
		top: 15vmax;`;
		else
			return `
			top: 160vmax;
		`;
	}}
	${(props) => {
		let colors = props.colors;
		if (props.borderTop)
			return `
		border-top: 2px solid;
	border-image-slice: 1;
	border-image-source: linear-gradient(to right, ${colors[props.pos][0]}, ${colors[props.pos][1]});`;
		else if (props.borderBottom)
			return `
		border-bottom: 2px solid;
	border-image-slice: 1;
	border-image-source: linear-gradient(to right, ${colors[props.pos][0]}, ${colors[props.pos][1]});`;
		else if (props.borderBoth)
			return `
		border-top: 2px solid;
		border-bottom: 2px solid;
	border-image-slice: 1;
	border-image-source: linear-gradient(to right, ${colors[props.pos][0]}, ${colors[props.pos][1]});`;
	}}
`;

export const InvertedDiagonalBar = styled(DiagonalBar)`
	position: relative;
	width: 150vw;
	transform: rotate(-23deg);
	left: -8vw;
	${(props) => {
		if (props.first)
			return `
		top: 15vmax;`;
		else
			return `
			top: 80vmax;
		`;
	}}
	border-bottom: 2px solid;
	border-image-slice: 1;
	${(props) => {
		let colors = props.colors;
		if (props.borderTop)
			return `
		border-top: 2px solid;
	border-image-slice: 1;
	border-image-source: linear-gradient(to right, ${colors[props.pos][0]}, ${colors[props.pos][1]});`;
		else if (props.borderBottom)
			return `
		border-bottom: 2px solid;
	border-image-slice: 1;
	border-image-source: linear-gradient(to right, ${colors[props.pos][0]}, ${colors[props.pos][1]});`;
		else if (props.borderBoth)
			return `
		border-top: 2px solid;
		border-bottom: 2px solid;
	border-image-slice: 1;
	border-image-source: linear-gradient(to right, ${colors[props.pos][0]}, ${colors[props.pos][1]});`;
	}}
`;

export const Spanlink = styled(motion.span)`
	a {
		color: inherit;

		&:hover {
			color: #051e3e;
		}
	}
`;

export const HorizontalBar = styled(motion.div)`
	height: 10vw;
	background: var(--grey);
	position: relative;
	width: 100vw;
	${(props) => {
		if (props.first)
			return `
		top: 15vw;`;
		else
			return `
			top: 30vw;
		`;
	}}
	${(props) => {
		let colors = props.colors;
		if (props.borderTop)
			return `
		border-top: 2px solid;
	border-image-slice: 1;
	border-image-source: linear-gradient(to right, ${colors[props.pos][0]}, ${colors[props.pos][1]});`;
		else if (props.borderBottom)
			return `
		border-bottom: 2px solid;
	border-image-slice: 1;
	border-image-source: linear-gradient(to right, ${colors[props.pos][0]}, ${colors[props.pos][1]});`;
		else if (props.borderBoth)
			return `
		border-top: 2px solid;
		border-bottom: 2px solid;
	border-image-slice: 1;
	border-image-source: linear-gradient(to right, ${colors[props.pos][0]}, ${colors[props.pos][1]});`;
	}}
`;
