import styled from "styled-components";
import { motion } from "framer-motion";

export const Wrapper = styled.div`
	maxwidth: var(--maxWidth);
	height: 100vh;
	background: var(--black);
	margin: 0 auto;
	overflow: scroll;
`;

export const Content = styled.div`
	display: flex;
	align-items: center;
	height: 100vh;
`;

// export const CircleContainer = styled(motion.div)`
// 	position: relative;
// 	top: 50%;
// 	left: 50%;
// 	width: 1000px;
// 	height: 1000px;
// 	background-color: transparent;
// 	border-radius: 500px;
// 	-webkit-transform: translate(-50%, -50%);
// 	transform: translate(-50%, -50%);

// 	transition: background 0.5s ease-in-out;
// `;

export const OuterCircle = styled(motion.div)`
	position: absolute;
	top: 50%;
	left: 50%;
	width: ${(props) => (props.width ? `${props.width}px` : "750px")};
	height: ${(props) => (props.width ? `${props.width}px` : "750px")};
	background-color: ${(props) => {
		return props.isHovering ? "var(--darkGrey)" : "transparent";
	}};
	border-radius: 375px;
	border: ${(props) => {
		return props.isHovering ? "solid 1px var(--grey)" : "transparent";
	}};
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);

	transition: background 1s ease-in-out;

	@media all and (max-width: 1000px) {
		transform: translate(-50%, -50%) scale(0.85);
	}
	@media all and (max-width: 600px) {
		transform: translate(-50%, -50%) scale(0.65);
	}
	@media all and (max-width: 480px) {
		transform: translate(-50%, -50%) scale(0.4);
	}
`;

export const InnerCircle = styled(motion.div)`
	position: absolute;
	top: 50%;
	left: 50%;
	width: ${(props) => (props.width ? `${props.width}px` : "600px")};
	height: ${(props) => (props.width ? `${props.width}px` : "600px")};
	background-color: ${(props) => {
		return props.isHovering ? "var(--grey)" : "transparent";
	}};
	border-radius: 300px;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);

	transition: background 0.5s ease-in-out;
`;

export const JoystickContainer = styled(motion.div)`
	position: absolute;
	left: 50%;
	top: 50%;
	margin-top: ${(props) => (props.width ? `-${props.width / 2}px` : "-75px")};
	margin-left: ${(props) => (props.width ? `-${props.width / 2}px` : "-75px")};

	color: transparent;
	border-style: solid;
	border-color: transparent;
	border-width: 2px;
	border-radius: ${(props) => (props.width ? `${props.width / 2}px` : "75px")};
	width: ${(props) => (props.width ? `${props.width}px` : "150px")};
	height: ${(props) => (props.width ? `${props.width}px` : "150px")};
	background-image: linear-gradient(black, black), linear-gradient(to bottom right, magenta, gold);
	background-origin: border-box;
	background-clip: content-box, border-box;
`;

export const PointerImage = styled(motion.img)`
position: absolute;
	width: ${(props) => props.width}px;
	height: ${(props) => props.width}px;
	visibility: ${(props) => (props.visible ? "visible" : "hidden")};
	left: 50%;
	transform: translateX(-50%);
	
	animation: ${(props) => {
		if (props.visible) return "blink 3s infinite";
		return "none";
	}};
	animation-delay: 2s;
	opacity: 0;

	@keyframes blink {
		0% {
			opacity: 0.7;
			top: 20%;
		}
		60% {
			opacity: 0;
		}
		100% {
			top: 50%;
		}
	}
	@-webkit-keyframes blink {
		0% {
			opacity: 0.7;
			top: 20%;
		}
		60% {
			opacity: 0;
		}
		100% {
			top: 50%;
		}
	}

	
`

export const ExpandArrowImage = styled(motion.img)`
	position: absolute;
	width: ${(props) => props.width}px;
	height: ${(props) => props.width}px;
	visibility: ${(props) => (props.visible ? "visible" : "hidden")};
	transform: ${(props) => {
		if (props.rot === "top") return "rotate(180deg)";
		if (props.rot === "right") return "rotate(270deg)";
		if (props.rot === "bot") return "rotate(0deg) ";
		else return "rotate(90deg)";
	}};

	top: ${(props) => {
		if (props.rot === "top") return `-${2 * props.width}px`;
		if (props.rot === "left" || props.rot === "right")
			return `${(props.width * 5) / 2 - props.width / 2}px`;
		return "auto";
	}};
	bottom: ${(props) => {
		if (props.rot === "bot") return `-${2 * props.width}px`;
		return "auto";
	}};
	left: ${(props) => {
		if (props.rot === "left") return `-${2 * props.width}px`;
		if (props.rot === "top" || props.rot === "bot")
			return `${(props.width * 5) / 2 - props.width / 2}px`;
		return "auto";
	}};
	right: ${(props) => {
		if (props.rot === "right") return `-${2 * props.width}px`;
		return "auto";
	}};

	animation: ${(props) => {
		if (props.visible) {
			if (props.rot === "top") return "blink-animation-top 2s infinite";
			if (props.rot === "bot") return "blink-animation-bot 2s infinite";
			if (props.rot === "right") return "blink-animation-right 2s infinite";
			return "blink-animation-left 2s infinite";
		}
		return "none";
	}};
	animation-delay: 1s;
	-webkit-animation: ${(props) => {
		if (props.visible) {
			if (props.rot === "top") return "blink-animation-top 2s infinite";
			if (props.rot === "bot") return "blink-animation-bot 2s infinite";
			if (props.rot === "right") return "blink-animation-right 2s infinite";
			return "blink-animation-left 2s infinite";
		}
		return "none";
	}};

	@keyframes blink-animation-top {
		to {
			top: ${(props) => `-${4 * props.width}px`};
			opacity: 0;
		}
	}
	@-webkit-keyframes blink-animation-top {
		to {
			top: ${(props) => `-${4 * props.width}px`};
			opacity: 0;
		}
	}

	@keyframes blink-animation-left {
		to {
			left: ${(props) => `-${4 * props.width}px`};
			opacity: 0;
		}
	}
	@-webkit-keyframes blink-animation-left {
		to {
			left: ${(props) => `-${4 * props.width}px`};
			opacity: 0;
		}
	}

	@keyframes blink-animation-right {
		to {
			right: ${(props) => `-${4 * props.width}px`};
			opacity: 0;
		}
	}
	@-webkit-keyframes blink-animation-right {
		to {
			right: ${(props) => `-${4 * props.width}px`};
			opacity: 0;
		}
	}

	@keyframes blink-animation-bot {
		to {
			bottom: ${(props) => `-${4 * props.width}px`};
			opacity: 0;
		}
	}
	@-webkit-keyframes blink-animation-bot {
		to {
			bottom: ${(props) => `-${4 * props.width}px`};
			opacity: 0;
		}
	}
`;

export const NavigationArrowImage = styled(motion.img)`
	position: absolute;
	width: ${(props) => props.width}px;
	height: ${(props) => props.width}px;
	visibility: ${(props) => (props.visible ? "visible" : "hidden")};
	transform: ${(props) => {
		if (props.rot === "top") return "rotate(180deg)";
		if (props.rot === "right") return "rotate(270deg)";
		if (props.rot === "bot") return "rotate(0deg) ";
		else return "rotate(90deg)";
	}};

	top: ${(props) => {
		if (props.rot === "top") return `-5px`;
		if (props.rot === "left" || props.rot === "right")
			return `${(props.width * 20) / 2 - props.width / 2}px`;
		return "auto";
	}};
	bottom: ${(props) => {
		if (props.rot === "bot") return `-5px`;
		return "auto";
	}};
	left: ${(props) => {
		if (props.rot === "left") return `-5px`;
		if (props.rot === "top" || props.rot === "bot")
			return `${(props.width * 20) / 2 - props.width / 2}px`;
		return "auto";
	}};
	right: ${(props) => {
		if (props.rot === "right") return `-5px`;
		return "auto";
	}};
`;

export const NavCenterContainer = styled(motion.div)`
	position: relative;
	width: 10px;
	height: 10px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
`;

export const NavLink = styled(motion.h1)`
	position: absolute;
	font-size: var(--fontXL);
	background: #000000;
	font-weight: 400;
	text-align: center;
	width: 210px;
	color: var(--white);
	padding: 10px 10px 10px 10px;
	border-radius: 40px;
	transition-duration: 0.3s;
	visibility: ${(props) => (props.visible ? "visible" : "hidden")};

	${(props) => {
		if (props.pos === "top") {
			return `
            top: -390px;
            left: -100px;`;
		}
	}}

	${(props) => {
		if (props.pos === "bot") {
			return `
            bottom: -390px;
            left: -100px;`;
		}
	}}

    ${(props) => {
		if (props.pos === "left") {
			return `
            top: -56px;
            left: -510px;`;
		}
	}}

    ${(props) => {
		if (props.pos === "right") {
			return `
            top: -56px;
            right: -510px;`;
		}
	}} 

    //Hover Effect
    ${(props) => {
		let colors = props.colors;
		if (props.selected)
			return `background-image: linear-gradient(to bottom right, ${colors[props.pos][0]}, ${
				colors[props.pos][1]
			});
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            text-fill-color: transparent;
			transform: scale(1.1);`;
		return ``;
	}}
    

    @media all and (max-width: 1000px) {
		font-size: var(--fontL);
		transform: ${(props) => {
			if (props.pos === "top") return "none";
			if (props.pos === "right") return "rotate(90deg)";
			if (props.pos === "left") return "rotate(-90deg)";
			else return "none";
		}};

		${(props) => {
			if (props.pos === "top") {
				return `
                top: -375px;
                left: -100px;`;
			}
		}}

		${(props) => {
			if (props.pos === "bot") {
				return `
                bottom: -375px;
                left: -100px;`;
			}
		}}
    
        ${(props) => {
			if (props.pos === "left") {
				return `
                top: -45px;
                left: -430px;`;
			}
		}}
    
        ${(props) => {
			if (props.pos === "right") {
				return `
                top: -45px;
                right: -430px;`;
			}
		}}
	}

	@media all and (max-width: 600px) {
		font-size: var(--fontL);
		transform: ${(props) => {
			if (props.pos === "top") return "none";
			if (props.pos === "right") return "rotate(90deg)";
			if (props.pos === "left") return "rotate(-90deg)";
			else return "none";
		}};

		${(props) => {
			if (props.pos === "top") {
				return `
                top: -375px;
                left: -100px;`;
			}
		}}

		${(props) => {
			if (props.pos === "bot") {
				return `
                bottom: -375px;
                left: -100px;`;
			}
		}}

		${(props) => {
			if (props.pos === "left") {
				return `
                top: -40px;
                left: -430px;`;
			}
		}}

		${(props) => {
			if (props.pos === "right") {
				return `
                top: -40px;
                right: -430px;`;
			}
		}}
	}

	@media all and (max-width: 480px) {
		font-size: 3rem;
		transform: ${(props) => {
			if (props.pos === "top") return "none";
			if (props.pos === "right") return "rotate(90deg)";
			if (props.pos === "left") return "rotate(-90deg)";
			else return "none";
		}};

		${(props) => {
			if (props.pos === "top") {
				return `
                top: -410px;
                left: -95px;`;
			}
		}}

		${(props) => {
			if (props.pos === "bot") {
				return `
                bottom: -410px;
                left: -95px;`;
			}
		}}

		${(props) => {
			if (props.pos === "left") {
				return `
                top: -52px;
                left: -440px;`;
			}
		}}

		${(props) => {
			if (props.pos === "right") {
				return `
                top: -52px;
                right: -440px;`;
			}
		}}
	}
`;

/* 
${(props) => {
		if (props.selected)
			return `border-color: var(--white);
		transform: scale(1.1);
        filter: invert(0.9);
		cursor: pointer;`;
		return ``;
	}}
    */
