import { Link } from "react-router-dom";
import MSTPaper from "../../files/MSTShort&SmallForStudents_AndrasZolyomi.pdf";
import styled from "styled-components";
import { motion } from "framer-motion";
import { contentVariants } from "../variants";

const Wrapper = styled(motion.div)`
  maxwidth: var(--maxWidth);
  background: var(--black);
  margin: 0 auto;
  overflow: auto;
`;

const Content = styled(motion.div)`
  overflow: auto;
`;

const Container = styled(motion.div)`
  height: 100vh;
  width: 100vw;
  background: var(--black);
  margin: 0 auto;
  position: relative;
  margin-bottom: 0;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
`;

const ViewResearchButton = styled.button`
  background-color: white;
  opacity: 0.9;
  border-radius: 10px;
  padding: 10px;
  margin-top: 20px;
  width: 200px;
  height: 50px;
  border: none;
  font-family: Helvetica;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  transition-duration: 0.2s;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, magenta, cyan);
    z-index: -1;
    border-radius: 10px;
    top: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: left;
    transition-duration: 0.2s;
  }

  &:hover {
    transform: scale(1.02);
  }

  &:hover:before {
    transform: scaleX(1);
  }

  &:active {
    transform: scale(0.9);
  }

  &:focus {
    outline: none;
  }

  &:focus:before {
    transform: scaleX(1);
  }
`;

const Research = () => {
  return (
    <Wrapper
      style={{ width: "100vw", height: "100vh", backgroundColor: "black" }}
    >
      <Content
        variants={contentVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <Container>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              width: "100vw",
              padding: 40,
            }}
          >
            <h1
              style={{
                color: "white",
                fontFamily: "Helvetica",
                fontSize: 40,
                margin: 0,
              }}
            >
              Research
            </h1>
          </div>
          {/* nicely-styled list of research projects with animations */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              width: "100vw",
              padding: 40,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                width: "50vw",
                minWidth: 300,
                border: "solid 1px #FFFFFF2e",
                borderRadius: 20,
                padding: 40,
              }}
            >
              <h1
                style={{
                  color: "white",
                  fontFamily: "Helvetica",
                  fontSize: 32,
                  margin: 0,
                }}
              >
                Spanning Trees for Students
              </h1>
              <p
                style={{
                  color: "white",
                  fontFamily: "Helvetica",
                  fontSize: 18,
                  marginTop: 5,
                  lineHeight: 1.3,
                }}
              >
                A summary of the foundational work in Graph Theory,{" "}
                <span
                  style={{
                    color: "white",
                    fontFamily: "Helvetica",
                    fontSize: 18,
                    marginTop: 5,
                    lineHeight: 1.3,
                    fontStyle: "italic",
                  }}
                >
                  Spanning Trees - Short & Small
                </span>{" "}
                by{" "}
                <span
                  style={{
                    color: "white",
                    fontFamily: "Helvetica",
                    fontSize: 18,
                    marginTop: 5,
                    lineHeight: 1.3,
                    fontWeight: "bold",
                  }}
                >
                  R. Ravi et. al.
                </span>
                , written for students interested in the field but discouraged
                by the volume of prerequisite knowledge necessary. Succeeded in
                explaining the advanced content to undergraduates who had not
                yet taken a Graph Theory course.
              </p>
              <Link to="/research/mst">
                <ViewResearchButton>View Paper</ViewResearchButton>
              </Link>
            </div>
          </div>
        </Container>
      </Content>
    </Wrapper>
  );
};

const MSTPaperComponent = () => {
  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <object data={MSTPaper} type="application/pdf" width="100%" height="100%">
        <p>Research PDF</p>
      </object>
    </div>
  );
};

export { MSTPaperComponent };
export default Research;
