import ResumePDF from "../../files/zolyomi_resume.pdf";
const Resume = () => {
	return (
		<div style={{width: '100vw', height: '100vh'}}>
			<embed src={ResumePDF} width="100%" height="100%" alt="pdf" pluginspage="http://www.adobe.com/products/acrobat/readstep2.html" />
		</div>
	);
};

export default Resume;

