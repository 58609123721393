import {useState, useEffect} from "react";
import styled from "styled-components";
// require('dotenv').config();
const axios = require('axios');

const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: black;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const ArticlesContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70%;
    overflow-y: scroll;
`
const Article = styled.div`
    height: 40px;
    padding: 10px;
    margin: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    border: solid 1px gray;
    border-radius: 10px;
    font-size: 16px;
    color: white;
    cursor: pointer;
    transition: transform 0.1s, background-color 0.1s;

    &:hover {
        background-color: #2e2e2e;
        transform: scale(1.02);
    }
    
`
const ArticleText = styled.div``
const ArticleScore = styled.div`
    color: ${props => {
        if (Number(props.sarcastic) > 0.95) return "green";
        if (Number(props.sarcastic < 0.05)) return "red";
        return "gray";
    }};
`

const ContentContainer = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const ContentElem = styled.div`
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`
const Spacer = styled.div`
    width: ${props => props.width}px;
`
const Text = styled.p`
    font-size: 16px;
    color: white;
`
const Button = styled.button`
    background: ${props => props.primary ? "linear-gradient(to right, #1e3c72, #2a5298)" : "#2e2e2e"};
    color: ${props => props.primary ? "white" : "#457fca"};

    font-size: 16px;
    margin: 0.5em;
    padding: 0.5em 0.8em;
    border: 1px solid #8c8c8c;
    border-radius: 10px;
    transition-duration: 200ms;

    &:hover {
        cursor: pointer;
        transform: scale(1.2);
    }
`

const ErrorMessage = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px;
    color: red;
    border: solid 1px red;
    border-radius: 40px;
`

const ErrorText = styled.h4`
    font-size: 30px;
    color: red;
`

const NYT_API_POPULAR_URL="https://api.nytimes.com/svc/topstories/v2";
const NYT_API_POPULAR_SLUG = `?api-key=${process.env.REACT_APP_NYT_API_KEY}`

const Sarcasm = () => {
    const [input, setInput] = useState("");
    const [articleElements, setArticleElements] = useState([]);
    const [response, setResponse] = useState("");
    const [isIterating, setIsIterating] = useState(false);
    const [apiRunning, setApiRunning] = useState(false); // useState("loading");
    const [isResetting, setIsResetting] = useState(false);
    async function send_req() {
        const result = await axios.post(`https://api.zolyo.me/sarcasm_detection`, {query:input});
        setResponse(JSON.stringify(result.data));
    }

    useEffect(() => {
        get_nyt_articles_and_score_results();
    }, [])

    useEffect(() => {
        (async () => {
            try {
                const res = await axios.get(`https://api.zolyo.me/test`);
                console.log(res);
                if (res?.data?.test === true) setApiRunning(true);
                else setApiRunning(false);
            }
            catch (e) {
                setApiRunning(false);
            }
        })()
    })


    async function get_nyt_articles_and_score_results () {
        const res = await axios.get(`${NYT_API_POPULAR_URL}/home.json${NYT_API_POPULAR_SLUG}`);
        let results = res?.data?.results;
        let arrResults = results.map(e => e.title);
        const sarcasmDetection = await axios.post(`https://api.zolyo.me/sarcasm_detection`, {queries:arrResults, threshold:0.50});
        // console.log(sarcasmDetection.data);
        let accumulatedArticleElements = results.map((e, i) => ({title: e.title, url: e.url, sarcastic: sarcasmDetection.data[i].sarcastic, score: sarcasmDetection.data[i].score}))
        let sortedArticleElements = accumulatedArticleElements.sort((a, b) => (Number(a.score) > Number(b.score)) ? -1 : 1)
        setArticleElements(sortedArticleElements);
    }

    async function request_iteration_on_datasets() {
        setIsIterating(true);
        const res = await axios.post(`https://api.zolyo.me/sarcasm_iteration`);
        console.log(res?.data);
        setIsIterating(false);
        return res?.data;
    }

    async function request_model_reset() {
        setIsResetting(true);
        const res = await axios.post(`https://api.zolyo.me/sarcasm_reset`);
        console.log(res?.data);
        setIsResetting(false);
        return res?.data;
    }

    if (apiRunning === "loading") return (
        <Container>
            <ArticlesContainer>
                <Article>Loading...</Article>
            </ArticlesContainer>
        </Container>
    )
    if (!apiRunning) return (
        <Container>
            <ErrorMessage>
                <ErrorText>Error</ErrorText>
                <Text>api.zolyo.me is currently down due to hosting costs.</Text>
                <Text>Please <a style={{textDecoration: 'none'}}href="https://zolyo.me/contact">contact</a> the developer to test this feature.</Text>
            </ErrorMessage>
        </Container>
    )

    return (
        <Container>
            <ContentElem>
                <Text>Enter query string:{" "}</Text>
                <Spacer width={5} />
                <input style={{width: 800, padding: 10, borderRadius: 10, backgroundColor: "#2e2e2e", outline: 'none', border: 'none', color: 'white'}} type="text" onChange={(e) => setInput(e.target.value)}/>
                <Spacer width={40} />
                <Button primary onClick={() => send_req()}>Go!</Button>
            </ContentElem>
            <ContentElem>
                {response && <Text>Sarcastic: {JSON.parse(response).sarcastic}, score: {JSON.parse(response).score.toFixed(4)}</Text>}
            </ContentElem>
            <ContentContainer style={{flexDirection: 'row', justifyContent: 'center'}}>
                <Button style={{marginRight: '2em'}} primary onClick={() => get_nyt_articles_and_score_results()}>Refresh NYT!</Button>
                <Button style={{marginRight: '2em'}}  disabled={isIterating} onClick={() => request_iteration_on_datasets()}>{isIterating ? "Iterating..." : "Iterate over Onion Articles"}</Button>
                <Button style={{marginRight: '2em'}}  disabled={isResetting} onClick={() => request_model_reset()}>{isResetting ? "Resetting..." : "Reset Model"}</Button>
            </ContentContainer>
            <ContentContainer>
                <ArticlesContainer>
                    {articleElements.length === 0 && <Article>Loading...</Article>}
                    {articleElements.length > 0 && articleElements.map((e, index) => {
                        // console.log(e);
                        return (
                            <Article key={index} onClick={() => window.open(e.url)}>
                                <ArticleText>{e.title}</ArticleText>
                                <ArticleScore sarcastic={e.score}>{e.score}</ArticleScore>
                            </Article>
                        )
                    })}
                </ArticlesContainer>
            </ContentContainer>
            
        </Container>
    )
}

export default Sarcasm;
