import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import PuzzleZip from "../../../files/reubenbirth2023.zip";

const Wrapper = styled(motion.div)`
  maxwidth: var(--maxWidth);
  background: var(--black);
  margin: 0 auto;
  overflow: auto;
`;

const Content = styled(motion.div)`
  overflow: auto;
`;

const Container = styled(motion.div)`
  height: 100vh;
  width: 100vw;
  background: var(--black);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PuzzleDownloadButton = styled(motion.a)`
  margin-top: 40px;
  color: limegreen;
  padding: 20px;
  border: solid 1px limegreen;
  border-radius: 5px;
  transition-duration: 200ms;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    background-color: rgba(0, 150, 0, 0.3);
  }
`;

const ReubenBirth2023 = () => {
  return (
    <Wrapper>
      <Content>
        <Container>
          <h1 style={{ fontWeight: "bold", textAlign: "center" }}>
            To cross the bridge of death <br />
            you must answer me these puzzles three.
          </h1>
          {/* make a button that downloads the PuzzleZip file when clicked */}
          <PuzzleDownloadButton
            style={{ color: "green", padding: 20, border: "solid 1px green" }}
            href={PuzzleZip}
            download
          >
            Begin Your Quest
          </PuzzleDownloadButton>
        </Container>
      </Content>
    </Wrapper>
  );
};

export default ReubenBirth2023;
