import React from "react"; //{ useState, useEffect, useRef }
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import Circles from "../../../assets/aboutImages/circles.png";
import Semicircles from "../../../assets/aboutImages/semicircles.png";
import Squares from "../../../assets/aboutImages/squares.png";
import Controller from "../../../assets/aboutImages/controller.png";
import ResumeIcon from "../../../assets/aboutImages/resumeIcon.png";
import UpIcon from "../../../assets/aboutImages/up-icon.svg";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import FeaturedProjectsMenu from "./FeaturedProjectsMenu";
import ExperienceMenu from "./ExperienceMenu";
import useWindowSize from "./useWindowSize";

import { contentVariants } from "../../variants";

const Wrapper = styled(motion.div)`
  maxwidth: var(--maxWidth);
  background: var(--black);
  margin: 0 auto;
  overflow: auto;
`;

const Content = styled(motion.div)`
  overflow: auto;
`;

const Container = styled(motion.div)`
  height: 100vh;
  width: 100vw;
  background: var(--black);
  margin: 0 auto;
  position: relative;
  margin-bottom: 0;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
`;

const PageName = styled(motion.h1)`
  position: relative;
  font-size: 6.8vmax;
  margin-right: 2vw;
  ${(props) => {
    return `background-image: linear-gradient(to bottom right, ${
      colors[props.pos][0]
    }, ${colors[props.pos][1]});`;
  }}
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const colors = {
  top: ["gold", "magenta"],
  right: ["magenta", "cyan"],
  bot: ["cyan", "green"],
  left: ["#7b4397 0%, #7b4397 25%", "#dc2430 75%, #dc2430 100%"],
};

const Row = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const NarrowRowContainer = styled(motion.div)`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

const Text = styled(motion.text)`
  font-family: Outfit;
  font-size: 22px;
  font-weight: 300;
  color: #fcfafb;
  opacity: ${(props) => props.opacity ?? 1};
`;

const Heading = styled(motion.text)`
  font-family: Inter;
  font-weight: bold;
  font-size: 48px;
  color: white;
  opacity: 1;
`;

const Subheading = styled(Heading)`
  color: #fcfafa;
  opacity: 0.7;
`;

const Divider = styled(motion.hr)`
  width: 100%;
  background-color: #d9d9d9;
  opacity: 0.2;
  border: none;
  border: 1px solid #d9d9d9;
  border-radius: 7px 7px 7px 7px;
`;

const Image = styled(motion.img)`
  width: ${(props) => props.widthPercent ?? 30}%;
  height: 90%;
  flex: 1;
  object-fit: cover;
  transition-duration: 0.5s;
`;

const SpanPink = styled(motion.span)`
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: #fd71cd;
  transition-duration: 0.1s;
  cursor: pointer;

  &:hover {
    color: #e33daa;
  }
`;

const SpanRainbow = styled(motion.span)`
  font-family: inherit;
  font-size: inherit;
  font-weight: ${(props) => {
    if (props.bold) return "500;";
    else return "inherit;";
  }};

  ${(props) => {
    let gradientColors = props.colors ?? colors["top"];
    let start = props.start ?? "top left";
    return `background: -webkit-linear-gradient(${start}, ${gradientColors[0]}, ${gradientColors[1]});`;
  }}
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Spacer = styled(motion.div)`
  height: ${(props) => props.height ?? 20}px;
  scroll-snap-align: none;
`;

const HeaderBar = styled(motion.div)`
  padding-top: 30px;
  width: 100%;
  height: 16vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const HeaderIconsContainer = styled(motion.div)`
  display: flex;
  flex-direction: row;
`;

const ControllerImageAnimatedContainer = styled(motion.div)`
  height: 7vmax;
  width: 7vmax;

  min-height: 60px;
  min-width: 60px;
  border-radius: 50px;
  margin-left: 2vw;
  display: flex;
  justify-content: center;
  align-items: center;

  @keyframes ssg {
    0% {
      transform: rotate(0deg), scale(1);
    }
    25% {
      transform: rotate(360deg) scale(0.8);
    }
    50% {
      transform: rotate(360deg) scale(1.2);
    }
    75% {
      transform: rotate(360deg) scale(1);
    }
    100% {
      transform: rotate(0deg) scale(1);
    }
  }

  &:hover {
    cursor: pointer;
    animation: ssg 1.5s ease;
  }
`;

const IconAnimatedContainer = styled(motion.div)`
  height: 7vw;
  width: 7vh;

  min-height: 60px;
  min-width: 60px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-duration: 0.2s;
  transform: scale(1.2);

  &:hover {
    cursor: pointer;
    transform: scale(1.4);
  }
`;

const ControllerImage = styled(motion.img)`
  height: 50%;
  object-fit: cover;
`;

const About = () => {
  const windowSize = useWindowSize();
  const isSmallDevice = isMobile || window.innerWidth < 600;

  let align = isSmallDevice ? "none" : "center";

  const history = useHistory();
  return (
    <Wrapper>
      <Content
        variants={contentVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <Container id="scroller">
          <div style={{ scrollSnapAlign: align }}>
            <HeaderBar style={{ scrollSnapAlign: "none" }}>
              <HeaderIconsContainer>
                <ControllerImageAnimatedContainer
                  onClick={() => history.push("/joystick")}
                >
                  <ControllerImage src={Controller} alt="joystick" />
                </ControllerImageAnimatedContainer>

                <IconAnimatedContainer
                  onClick={() => window.open("/resume", "_blank")}
                >
                  <ControllerImage src={ResumeIcon} alt="resume" />
                </IconAnimatedContainer>
              </HeaderIconsContainer>
              <PageName pos={"top"}>./about</PageName>
            </HeaderBar>
            <Spacer height={60} />
            <Title windowSize={windowSize} />
          </div>

          <Spacer height={300} />

          <div style={{ scrollSnapAlign: align }}>
            <Experiences />
          </div>

          <Spacer height={400} />

          <div style={{ scrollSnapAlign: align }}>
            <FeaturedProjects />
          </div>

          <Spacer height={60} />

          <div style={{ scrollSnapAlign: align }}>
            <Links />
          </div>
          <Spacer height={80} />
        </Container>
      </Content>
    </Wrapper>
  );
};

const TitleContainer = styled(motion.div)`
  display: flex;
  flex: 2;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const TitleTextContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
`;

const Title = ({ windowSize }) => {
  const isSmallDevice = isMobile || windowSize.width < 500;
  return (
    <Row>
      <NarrowRowContainer style={{ minWidth: 400 }}>
        <TitleContainer>
          <TitleTextContainer style={{ width: isSmallDevice ? "80%" : "60%" }}>
            <Text>Hi, my name is</Text>
            <Spacer />
            <Heading>Andras Zolyomi</Heading>
            <Subheading>I bring app ideas to life.</Subheading>
            <Spacer height={40} />
            <Text opacity={0.96}>
              I'm a full-stack software engineer specialized in building
              end-to-end{" "}
              <SpanRainbow colors={["#61dafb", "gold"]} bold>
                user experience
              </SpanRainbow>{" "}
              flows.
            </Text>
            <Spacer height={10} />
            <Text opacity={0.96} style={{ maxWidth: 1045 }}>
              Currently, I'm focused on building gamified{" "}
              <SpanRainbow bold>3D UI/UX</SpanRainbow> and{" "}
              <SpanRainbow bold>privacy/security</SpanRainbow> features for{" "}
              <SpanPink onClick={() => window.open("https://roblox.com")}>
                Roblox
              </SpanPink>
              .
            </Text>
            <Spacer height={50} />
            <Divider />
          </TitleTextContainer>
        </TitleContainer>
        {!isMobile && windowSize.width > 1300 && <Image src={Semicircles} />}
      </NarrowRowContainer>
    </Row>
  );
};

const FeaturedProjectsContainer = styled(motion.div)`
  display: flex;
  flex: 7;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transform: translateY(-30px);
`;

const FeaturedProjectsMenuContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FeaturedRowContainer = styled(motion.div)`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 85%;
`;

const FeaturedProjects = ({ windowSize }) => {
  const history = useHistory();
  return (
    <Row>
      <FeaturedRowContainer>
        {!isMobile && window.innerWidth > 1300 && (
          <>
            <Image src={Squares} />
            <div style={{ display: "flex", flex: 1 }} />
          </>
        )}
        <FeaturedProjectsContainer>
          <FeaturedProjectsMenuContainer>
            <Heading>Featured Projects</Heading>
            <Spacer height={50} />
            <FeaturedProjectsMenu
              colors={colors["top"]}
              windowSize={windowSize}
            />
            <Spacer height={30} />
            <Text>
              {"("}See the full list{" "}
              <SpanPink
                onClick={() => {
                  history.push("/projects");
                }}
              >
                here
              </SpanPink>
              {")"}
            </Text>
            <Spacer height={30} />
            <Divider />
          </FeaturedProjectsMenuContainer>
        </FeaturedProjectsContainer>
      </FeaturedRowContainer>
    </Row>
  );
};

const ExperiencesContainer = styled(motion.div)`
  display: flex;
  flex: 7;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transform: translateY(-10px);
`;

const ExperiencesMenuContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ExperienceRowContainer = styled(motion.div)`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 85%;
`;

const Experiences = ({ windowSize }) => {
  return (
    <Row>
      <ExperienceRowContainer>
        <ExperiencesContainer>
          <ExperiencesMenuContainer>
            <Heading>Experience</Heading>
            <Spacer height={50} />
            <ExperienceMenu colors={colors["top"]} windowSize={windowSize} />
            <Spacer height={30} />
            <Text>
              {"("}See my resume{" "}
              <SpanPink onClick={() => window.open("/resume", "_blank")}>
                here
              </SpanPink>
              {")"}
            </Text>
            <Spacer height={30} />
            <Divider />
          </ExperiencesMenuContainer>
        </ExperiencesContainer>
        {!isMobile && window.innerWidth > 1300 && (
          <>
            <div style={{ display: "flex", flex: 1 }} />
            <Image src={Circles} />
          </>
        )}
      </ExperienceRowContainer>
    </Row>
  );
};

const LinksBar = styled(motion.div)`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

const LinkText = styled(Heading)`
  font-size: 30px;
  cursor: pointer;
  opacity: 0.5;
  transition-duration: 0.2s;

  &:hover {
    opacity: 1;
  }
`;

const UpImageContainer = styled(motion.div)`
  transition-duration: 0.5s;
  z-index: 2;
  opacity: 0.5;
  padding: 30px 0px 30px 0px;
  &:hover {
    cursor: pointer;
    opacity: 1;
    transform: translateY(-25px);
  }
`;

const DisplayLinks = [
  { display: "Contact", uri: "/contact", pos: "left" },
  { display: "Resume", uri: "/resume", pos: "bot" },
  { display: "Projects", uri: "/projects", pos: "right" },
];

const Links = () => {
  const history = useHistory();

  const scroll = () => {
    document
      .getElementById("scroller")
      .scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <LinksBar>
      <div>
        <UpImageContainer onClick={scroll}>
          <img src={UpIcon} style={{ width: 60 }} alt="up" />
        </UpImageContainer>
      </div>
      {DisplayLinks.map((DisplayLink, id) => (
        <LinkText onClick={() => history.push(DisplayLink.uri)}>
          {DisplayLink.display}
        </LinkText>
      ))}
    </LinksBar>
  );
};

export { About };
